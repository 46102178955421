import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "", "justify-space-between": "" } },
    [
      _c(
        VFlex,
        { attrs: { xs12: "" } },
        [
          _c(
            VCard,
            [
              _c(VCardText, { staticClass: "card-content" }, [
                _c("p", { staticClass: "card-title" }, [
                  _vm._v("We've moved to CentricOS!")
                ]),
                _c("p", { staticClass: "card-subtitle" }, [
                  _vm._v(
                    "\n          You can still do the same things, just in a new place.\n          "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "card-subtitle-link",
                      attrs: { href: _vm.url, target: "_blank" }
                    },
                    [
                      _vm.isBrandRoute || _vm.isSiteRoute
                        ? _c("span", [_vm._v(_vm._s(_vm.siteMessageText))])
                        : _c("span", [
                            _vm._v(
                              "Open " + _vm._s(_vm.messageText) + " on Centric"
                            )
                          ])
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        VFlex,
        { attrs: { xs12: "" } },
        [
          _c(VImg, {
            attrs: { src: require("@/assets/menu-manager.svg"), contain: "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }