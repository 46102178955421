<template>
  <v-layout row wrap justify-space-between>
    <v-flex xs12>
      <v-card>
        <v-card-text class="card-content">
          <p class="card-title">We've moved to CentricOS!</p>
          <p class="card-subtitle">
            You can still do the same things, just in a new place.
            <a :href="url" target="_blank" class="card-subtitle-link">
              <span v-if="isBrandRoute || isSiteRoute">{{ siteMessageText }}</span>
              <span v-else>Open {{ messageText }} on Centric</span>
            </a>
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12>
      <v-img :src="require('@/assets/menu-manager.svg')" contain></v-img>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('sites', ['active_site']),
    stage() {
      return process.env.VUE_APP_STAGE || 'dev';
    },
    url() {
      const baseURL = this.stage !== 'v1' ? `${this.stage}.centricos.io` : 'centricos.io';
      const routeName = this.$route.name;
      const params = this.$route.params.site_id;
      let route = '';
      if (routeName === 'users-listing') {
        route = `https://${baseURL}/admin-panel-users`;
      } else if (routeName === 'announcement-listing') {
        route = `https://${baseURL}/announcements`;
      } else if (
        (routeName === 'brand-new' ||
          (this.$route.name === 'location-settings' && !this.$route.params.location_id)) &&
        params
      ) {
        route = `https://${baseURL}/sites/${params}/menu-hours`;
      } else if (routeName === 'site-new') {
        route = `https://${baseURL}/sites`;
      }
      return route;
    },
    messageText() {
      let msg;
      if (this.$route.name === 'users-listing') {
        msg = 'Users';
      } else if (this.$route.name === 'announcement-listing') {
        msg = 'Announcement';
      } else if (this.$route.name === 'brand-new') {
        msg = 'Site';
      } else if (this.$route.name === 'site-new') {
        msg = 'Site';
      }
      return msg;
    },
    siteMessageText() {
      let msg;
      if (
        this.$route.name === 'brand-new' ||
        (this.$route.name === 'location-settings' && !this.$route.params.location_id)
      ) {
        const siteName = this.active_site?.name;
        msg = `Navigate to ${siteName} in Centric`;
      } else if (this.isSiteRoute) {
        msg = `Navigate to Site list in Centric`;
      }
      return msg;
    },
    isBrandRoute() {
      return (
        this.$route.name === 'brand-new' ||
        (this.$route.name === 'location-settings' && !this.$route.params.location_id)
      );
    },
    isSiteRoute() {
      return this.$route.name === 'site-new';
    },
  },
};
</script>
<style scoped>
.card-content {
  padding: 24px !important;
}
.card-title {
  color: #0d73d8;
  font-size: 38px;
}
.card-subtitle {
  color: #090c9b;
  margin-bottom: 0 !important;
  font-size: 18px;
}
.card-subtitle-link {
  color: #090c9b;
  margin-bottom: 0 !important;
  font-size: 18px;
  text-decoration: underline;
}
</style>
